import React from 'react'
import { Link } from 'react-router-dom'

function NotFound () {
  return (
        <div className="container">
            <div className="d-flex justify-content-center flex-column align-items-center" style={{ height: 100 + 'vh' }}>
                <div className="alert alert-danger" role="alert">
                    <span>
                        Ошибка!
                    </span>
                    <br/>
                    <span>
                       Страница не найдена!
                    </span>
                </div>
                <Link className="alert alert-primary" to="/">Вернуться на главную</Link>
            </div>
        </div>
  )
}
export default NotFound
