import isJsonResponse from './isJsonResponse'

function request (url, method, data, headers, type) {
  if (type === undefined) type = 'json'
  const common = {
    method,
    headers: {
      Accept: 'application/json',
      ...headers
    }
  }

  const body =
        data !== null
          ? {
              headers: (type === 'json')
                ? {
                    'Content-Type': 'application/json'
                  }
                : {},
              body: (type === 'json') ? JSON.stringify(data) : data
            }
          : { headers: {} }

  return fetch('/api' + url, {
    ...common,
    ...body,
    headers: {
      ...common.headers,
      ...body.headers
    }
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw response
    })
    .then((response) => {
      if (isJsonResponse(response)) {
        return response.json()
      }
      return response.text()
    })
}

const api = {
  get: (url, headers = {}, type) => request(url, 'GET', null, headers, type),
  post: (url, data = null, headers = {}, type) => request(url, 'POST', data, headers, type)
}

export default api
