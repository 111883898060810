import React, { useContext } from 'react'
import { Card, NavLink } from 'react-bootstrap'
import GetAndShowImage from '../../Files/GetAndShowImage'
import { AppContext } from '../../App'
import PropTypes from 'prop-types'

PublicPostShort.propTypes = {
  post: PropTypes.object
}

function PublicPostShort (props) {
  const post = props.post
  const { setSinglePost, setShowHello } = useContext(AppContext)

  return (
        <NavLink href="#" onClick={(event) => {
          event.preventDefault()
          setSinglePost(post)
          setShowHello(false)
        }
        }>
            <Card>
                <GetAndShowImage fileName={post.image} altText={post.header}/>
                <Card.Body>
                    <Card.Title className="text-dark">{post.header}</Card.Title>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Подробнее...</small>
                </Card.Footer>
            </Card>
        </NavLink>
  )
}

export default PublicPostShort
