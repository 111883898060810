import React, { useState } from 'react'
import { Button, Form, FormText, Nav } from 'react-bootstrap'
import api, { parseError } from '../../Api'
import { useCookies } from 'react-cookie'

function ChangePassword () {
  const regPassValidation = /^(?=.*[0-9])(?=.*[\W])(?=.*[a-z])(?=.*[A-Z])[0-9\Wa-zA-Z]{8,}$/
  const [cookies, setCookie] = useCookies(['accessToken'])
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    phoneValidateCode: '',
    accessToken: cookies.accessToken
  })
  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(null)
  const [phoneCode, setPhoneCode] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const setToken = (token) => {
    setCookie('accessToken', token, { maxAge: 86400, httpOnly: true, sameSite: 'strict', secure: true })
  }

  const countDigits = (n) => {
    let i = 0
    for (; n > 1; i++) {
      n /= 10
    }
    return i
  }

  const handleChange = (event) => {
    const input = event.target
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value
    })
  }

  const getPhoneCode = (event) => {
    event.preventDefault()
    if (!regPassValidation.test(formData.newPassword)) {
      setErrors({ newPassword: 'Пароль от 8 символов, содержать хотя бы 1 цифру, хотя бы 1 спец. символ, состоять из латиницы, наличием строчных и прописных символов !Проверьте введённые данные!' })
      return
    }
    if (formData.newPassword !== formData.newPasswordConfirm) {
      setErrors({ newPasswordConfirm: 'Пароли не совпадают!Проверьте введённые данные!' })
      return
    }
    setButtonActive(false)
    setErrors({})
    setError(null)

    api
      .post('/user/changePassword', {
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword
      }, {
        Authorization: 'Bearer ' + formData.accessToken
      })
      .then(() => {
        setButtonActive(true)
        setPhoneCode(true)
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setButtonActive(true)
        setPhoneCode(false)
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (countDigits(formData.phoneValidateCode) !== 4) {
      setErrors({ phoneValidateCode: 'Некорректное значение кода!Проверьте введённые данные!' })
      return
    }
    if (formData.newPassword !== formData.newPasswordConfirm) {
      setErrors({ newPasswordConfirm: 'Пароли не совпадают!Проверьте введённые данные!' })
      return
    }
    setButtonActive(false)
    setErrors({})
    setError(null)

    api
      .post('/user/changePasswordConfirm', {
        phoneValidateCode: formData.phoneValidateCode
      }, {
        Authorization: 'Bearer ' + formData.accessToken
      })
      .then((response) => {
        setToken(response)
        setIsSuccess(true)
        setButtonActive(true)
        setPhoneCode(true)
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setButtonActive(true)
        setPhoneCode(false)
      })
  }

  return (
        <>
            <h1>Сменить пароль (подтверждение по номеру телефона)</h1>
            { !isSuccess &&
                <Form method="post" onSubmit={getPhoneCode} className="col-lg-6 col-xs-12 text-left mt-4">
                <Form.Group className={error ? 'd-block' : 'd-none'}>
                    <FormText className="h5 alert alert-danger">
                        {error}
                    </FormText>
                </Form.Group>

                <Form.Group controlId="formOldPassword" className={phoneCode ? 'd-none' : 'd-block'}>
                    <Form.Label>Текущий пароль</Form.Label>
                    <Form.Control type="password" placeholder="Текущий пароль" name="oldPassword"
                                  value={formData.oldPassword}
                                  onChange={handleChange}
                                  required/>
                    <Form.Text className="text-danger">
                        {errors.oldPassword}
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formNewPassword" className={phoneCode ? 'd-none' : 'd-block'}>
                    <Form.Label>Новый пароль (от 8 символов, содержать хотя бы 1 цифру, хотя бы 1 спец. символ, состоять из латиницы с наличием строчных и прописных символов)</Form.Label>
                    <Form.Control type="password" placeholder="Новый пароль" name="newPassword"
                                  value={formData.newPassword}
                                  onChange={handleChange}
                                  required/>
                    <Form.Text className="text-danger">
                        {errors.newPassword}
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formNewPasswordConfirm" className={phoneCode ? 'd-none' : 'd-block'}>
                    <Form.Label>Подтверждение нового пароля</Form.Label>
                    <Form.Control type="password" placeholder="Подтвердите новый пароль" name="newPasswordConfirm"
                                  value={formData.newPasswordConfirm}
                                  onChange={handleChange}
                                  required/>
                    <Form.Text className="text-danger">
                        {errors.newPasswordConfirm}
                    </Form.Text>
                </Form.Group>

                {phoneCode &&
                <>
                    <Form.Group controlId="formPhoneValidateCode">
                        <Form.Label>Проверочный код</Form.Label>
                        <Form.Control type="text" placeholder="Проверочный код" name="phoneValidateCode"
                                      value={formData.phoneValidateCode}
                                      onChange={handleChange}
                                      required/>
                        <Form.Text className="text-danger">
                            {errors.phoneValidateCode}
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" onClick={handleSubmit} disabled={!buttonActive}>
                        Подтвердить
                    </Button>
                </>
                }
                <Button variant="primary" type="submit" disabled={!buttonActive}
                        className={phoneCode ? 'd-none' : 'd-block'}>
                    Получить код подтверждения
                </Button>
            </Form>}
            { isSuccess &&
                <>
                    <h2 className="text-success">Пароль успешно изменён!</h2>
                    <Nav.Link href="/">Вернуться на главную</Nav.Link>
                </>
            }
        </>
  )
}

export default ChangePassword
