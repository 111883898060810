import React, { useEffect, useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap'
import api, { parseError } from '../../Api'
import PropTypes from 'prop-types'

GetMetersData.propTypes = {
  plot: PropTypes.number,
  token: PropTypes.string,
  showArrears: PropTypes.bool
}

GetMetersData.defaultProps = {
  showArrears: true
}

function GetMetersData (props) {
  const [data, setData] = useState({})
  const [plotNumber, setPlotNumber] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!data[props.plot] && plotNumber !== props.plot) {
      setPlotNumber(props.plot)
      setError(false)
      setIsLoading(true)
      api
        .post('/user/getMetersData', {
          plot: props.plot
        }, {
          Authorization: 'Bearer ' + props.token
        })
        .then((response) => {
          setData({
            ...data,
            [props.plot]: response
          })
          setIsLoading(false)
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setIsLoading(false)
        })
    }
  })
  return (
        <>
            <h2>История переданных показаний по участку {props.plot}</h2>
            { isLoading &&
            <div>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            </div>
            }
            <div className={error ? 'd-block' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
            </div>
            <Container fluid>
                <Row>
                    { data[props.plot] &&
                    <Table bordered hover>
                        <thead>
                        <tr>
                        <th>Счётчик</th>
                        <th>Показания</th>
                        <th>Отчётный период</th>
                          { props.showArrears
                            ? (<>
                                    <th>Задолженность</th>
                                    <th>Статус</th>
                            </>)
                            : (<></>)
                          }
                        </tr>
                        </thead>
                        <tbody>
                    {
                        data[props.plot].map((data, key) => (
                        <tr key={key}>
                        <td>{data.meter}</td>
                        <td>{data.value}</td>
                        <td>{data.dataPeriod}</td>
                          {
                            props.showArrears
                              ? (<>
                                  <td>{data.arrears}</td>
                                  <td>{ (data.isArrearsActive === 'true' && data.arrears > 0)
                                    ? <span className="text-danger">Не погашена</span>
                                    : <>
                                      {
                                        data.arrears > 0 ? 'Погашена' : '-'
                                      }
                                    </>
                                  }</td>
                               </>)
                              : (<></>)
                          }
                        </tr>
                        ))
                    }
                        </tbody>
                        </Table>
                    }
                </Row>
            </Container>
        </>
  )
}

export default GetMetersData
