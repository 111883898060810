import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'

GetAndShowImage.propTypes = {
  fileName: PropTypes.string,
  altText: PropTypes.string
}

/**
 * @TODO files must be cached
 */

function GetAndShowImage (props) {
  const [url, setUrl] = useState(null)
  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif']
  useEffect(() => {
    fetch('/api/user/getPublicFile', {
      method: 'POST',
      body: JSON.stringify({
        fileName: props.fileName
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.blob())
      .then((blob) => {
        if (allowedFileTypes.indexOf(blob.type) > -1) {
          setUrl(URL.createObjectURL(blob))
        } else { setUrl(null) }
      })
  }, [props])

  return (
        <>
          { url &&
              <Card.Img variant="top" src={url} alt={props.altText} className="d-inline-block img-fluid mh-100"/>
          }
        </>
  )
}

export default GetAndShowImage
