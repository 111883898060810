import React, { useContext } from 'react'
import { AppContext } from '../../App'
import CreateUsersFromFile from '../../Admin/CreateUsersFromFile'
import PersonalData from '../../User/PersonalData'
import Arrears from '../../User/Arrears'
import CreatePost from '../../Admin/CreatePost'
import SetArrearsAndRatesFromFile from '../../Admin/SetArrearsAndRatesFromFile'
import Payments from '../../User/Payments'
import LoadPaymentsFromFile from '../../Admin/LoadPaymentsFromFile'
import PrivateNews from '../PrivateNews'
import ChangePassword from '../../User/ChangePassword'
import UpdatePost from '../../Admin/UpdatePost'
import GetAllPlots from '../../Admin/GetAllPlots'
import UpdatePlot from '../../Admin/UpdatePlot'
import SendMetersData from '../../User/Meters/SendMetersData'
import GetMetersHistory from '../../User/Meters/GetMetersHistory'
import GetMetersDataFullArrears from '../../Meters/GetMetersDataFullArrears'
import LoadMetersArrearsFromFile from '../../Admin/LoadMetersArrearsFromFile'
import ViewMetersArrears from '../../Admin/ViewMetersArrears'
import ViewMeters from '../../Admin/ViewMeters'
import LoadMetersPaymentsFromFile from '../../Admin/LoadMetersPaymentsFromFile'

function Cabinet () {
  const { cabinetLink } = useContext(AppContext)

  switch (cabinetLink) {
    case 'personalData':
      return (<PersonalData/>)
    case 'changePassword':
      return (<ChangePassword/>)
    case 'arrears':
      return (<Arrears/>)
    case 'privateNews':
      return (<PrivateNews/>)
    case 'payments':
      return (<Payments/>)
    case 'createUsersFromFile':
      return (<CreateUsersFromFile/>)
    case 'loadPaymentsFromFile':
      return (<LoadPaymentsFromFile/>)
    case 'setArrearsAndRatesFromFile':
      return (<SetArrearsAndRatesFromFile/>)
    case 'createPost':
      return (<CreatePost/>)
    case 'updatePlot':
      return (<UpdatePlot/>)
    case 'getAllPlots':
      return (<GetAllPlots/>)
    case 'updatePost':
      return (<UpdatePost/>)
    case 'viewMeters':
      return (<ViewMeters/>)
    case 'sendMetersData':
      return (<SendMetersData/>)
    case 'getMetersHistory':
      return (<GetMetersHistory/>)
    case 'getMetersArrears':
      return (<GetMetersDataFullArrears/>)
    case 'loadMetersArrearsFromFile':
      return (<LoadMetersArrearsFromFile/>)
    case 'loadMetersPaymentsFromFile':
      return (<LoadMetersPaymentsFromFile/>)
    case 'viewMetersArrears':
      return (<ViewMetersArrears/>)
    default:
      return (<div/>)
  }
}

export default Cabinet
