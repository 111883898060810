import React, { useEffect, useState } from 'react'
import api, { parseError, parseErrors } from '../../Api'
import { useCookies } from 'react-cookie'
import Select from 'react-select'
import { Container, Row, Table } from 'react-bootstrap'

function ViewMetersArrears () {
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(false)
  const [metersData, setMetersData] = useState(null)
  const [plotNumber, setPlotNumber] = useState(null)
  const [cookies] = useCookies(['accessToken'])

  useEffect(() => {
    setErrors({})
    setError(null)
    api
      .get('/admin/getMetersData', { Authorization: 'Bearer ' + cookies.accessToken })
      .then((data) => {
        setMetersData(data)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(errors))
        setError(await parseError(error))
      })
  }, [])

  const onSelectVal = (newVal) => {
    console.log(newVal)
    setPlotNumber(Number(newVal.value))
  }

  return (
    <>
      <h1>Информация по счётчикам</h1>
      <div className={error ? 'd-block' : 'd-none'}>
        <h5 className="h5 alert alert-danger">
          {error}
        </h5>
      </div>
      {metersData &&
        <div>
          <Select options={metersData.plots}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  onChange={onSelectVal}/>
          {(plotNumber && metersData.data[plotNumber]) &&
            <Container fluid className="mt-5">
              <Row>
                <Table bordered hover>
                  <thead>
                  <tr>
                    <th>Счётчик</th>
                    <th>Показания</th>
                    <th>Отчётный период</th>
                    <th>Задолженность</th>
                    <th>Статус</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    metersData.data[plotNumber].map((data, key) => (
                      <tr key={key}>
                        <td>{data.meter}</td>
                        <td>{data.value}</td>
                        <td>{data.dataPeriod}</td>
                        <td>{data.arrears}</td>
                        <td>{(data.isArrearsActive === 'true' && data.arrears > 0)
                          ? <span className="text-danger">Не погашена</span>
                          : <>
                            {
                              data.arrears > 0 ? 'Погашена' : '-'
                            }
                          </>
                        }</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </Table>
              </Row>
            </Container>

          }
        </div>
      }
    </>
  )
}

export default ViewMetersArrears
