import React, { useContext } from 'react'
import { Nav } from 'react-bootstrap'
import { AppContext } from '../../App'
import styles from '../LeftColumn/LeftColumn.module.css'
import { LeftColumnContext } from '../LeftColumn/LeftColumn'

function CabinetLinks () {
  const { setCabinetLink, setRightColumnBlock, userRole, setSinglePost } = useContext(AppContext)
  const { burgerButton } = useContext(LeftColumnContext)

  const setFilterCabinet = (e, link) => {
    e.preventDefault()
    burgerButton.current.click()
    setRightColumnBlock('cabinet')
    setCabinetLink(link)
  }

  return (
        <>
            <Nav.Link disabled className={'mt-3 text-secondary ' + styles.linkBg}>Кабинет</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'personalData')} className="ml-3 text-primary">Личные данные</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'arrears')} className="ml-3 text-primary">Текущая задолженность и тариф</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'payments')} className="ml-3 text-primary">История платежей</Nav.Link>
            <Nav.Link onClick={(e) => {
              setFilterCabinet(e, 'privateNews')
              setSinglePost(false)
            }} className="ml-3 text-primary">Важная информация</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'changePassword')} className="ml-3 text-primary">Сменить пароль</Nav.Link>
            <Nav.Link disabled className={'text-secondary ' + styles.linkBg}>Счётчики</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'viewMeters')} className="ml-3 text-primary">Тарифы</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'sendMetersData')} className="ml-3 text-primary">Передать показания счётчиков</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'getMetersHistory')} className="ml-3 text-primary">История переданных данных по счётчикам</Nav.Link>
            <Nav.Link onClick={(e) => setFilterCabinet(e, 'getMetersArrears')} className="ml-3 text-primary">Задолженность по счётчикам</Nav.Link>
            { (userRole === 'admin') &&
                <>
                    <Nav.Link disabled className="mt-4 text-dark b fs-4">Административная панель</Nav.Link>
                    <Nav.Link disabled className={'text-secondary ' + styles.linkBg}>Участки</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'createUsersFromFile')} className="ml-3 text-primary">Создать пользователей из файла</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'setArrearsAndRatesFromFile')} className="ml-3 text-primary">Загрузить тарифы и задолженности участков из файла</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'loadPaymentsFromFile')} className="ml-3 text-primary">Загрузить историю платежей из файла</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'getAllPlots')} className="ml-3 text-primary">Редактировать&nbsp;/ посмотреть информацию об участках</Nav.Link>
                    <Nav.Link disabled className={'text-secondary ' + styles.linkBg}>Счётчики</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'viewMetersArrears')} className="ml-3 text-primary">Показания и задолженности</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'loadMetersArrearsFromFile')} className="ml-3 text-primary">Загрузить показания и задолженности из файла</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'loadMetersPaymentsFromFile')} className="ml-3 text-primary">Загрузить платежи по ЭЭ из файла</Nav.Link>
                    <Nav.Link disabled className={'text-secondary ' + styles.linkBg}>Статьи</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'createPost')} className="ml-3 text-primary">Создать статью</Nav.Link>
                    <Nav.Link onClick={(e) => setFilterCabinet(e, 'updatePost')} className="ml-3 text-primary">Редактировать статью</Nav.Link>
                </>
            }
        </>

  )
}

export default CabinetLinks
