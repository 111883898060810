import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import api, { parseError } from '../../Api'
import { Col, Container, Pagination, Row, Spinner, Table, Form, Button } from 'react-bootstrap'
import UpdatePlot from '../UpdatePlot'

function GetAllPlots () {
  const [error, setError] = useState(null)
  const [plotsData, setPlotsData] = useState(null)
  const [totalPlots, setTotalPlots] = useState(null)
  const [cookies] = useCookies(['accessToken'])
  const [isLoading, setIsLoading] = useState(false)
  const token = cookies.accessToken
  const [filters, setFilters] = useState({
    start: 0,
    limit: 10,
    sortBy: 'plotNumber',
    filterByArrears: '',
    filterByOwner: '',
    order: 'ASC'
  })

  const [paginationItems, setPaginationItems] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [onePlotNumber, setOnePlotNumber] = useState('')
  const [plotsToShow, setPlotsToShow] = useState(null)

  useEffect(() => {
    const items = []
    if (plotsToShow) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
                  <Pagination.Item key={number} active={number === activePage} onClick={() => {
                    setActivePage(number)
                    setFilters({ ...filters, start: (number - 1) * filters.limit })
                  }}>
                      {number}
                  </Pagination.Item>
        )
      }
    }
    setPaginationItems(items)
  }, [plotsToShow])

  useEffect(() => {
    if (!isLoading) {
      setError(null)
      setIsLoading(true)
      api
        .post('/admin/getAllPlots',
          filters,
          {
            Authorization: 'Bearer ' + token
          })
        .then((response) => {
          setPlotsData(response)
          setIsLoading(false)
          setTotalPlots(response.length)
          setTotalPages(Math.ceil(response.length / filters.limit))
          setFilters({
            ...filters,
            start: 0,
            limit: 10
          })
          setActivePage(1)
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setIsLoading(false)
        })
    }
  }, [filters.filterByOwner, filters.filterByArrears, filters.order, filters.sortBy])

  const handleChange = (event) => {
    const input = event.target
    setFilters({
      ...filters,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
      start: (input.name === 'limit') ? 0 : filters.start
    })
    if (input.name === 'limit') {
      setActivePage(1)
      setTotalPages(Math.ceil(totalPlots / input.value))
    }
  }
  const resetFilters = () => {
    setFilters({
      filterByArrears: '',
      filterByOwner: '',
      start: 0,
      order: 'ASC',
      sortBy: 'plotNumber',
      limit: 10
    }
    )
    setActivePage(1)
    setTotalPages(Math.ceil(totalPlots / 10))
    setOnePlotNumber('')
  }

  useEffect(() => {
    const items = []
    if (totalPlots <= filters.limit) {
      setPlotsToShow(plotsData)
    } else if (plotsData) {
      for (let number = (activePage - 1) * filters.limit; (number < totalPlots) && (number < (activePage * filters.limit)); number++) {
        if (plotsData[number]) {
          items.push(plotsData[number])
        }
      }
      setPlotsToShow(items)
    }
  }, [activePage, plotsData, totalPages])
  return (
        <>
            <h1>Информация об участках</h1>
            <Container fluid>
                <Row>
                    <Col md={6} lg={4} className="mt-4 mb-4">
                        <Form.Group controlId="arrears">
                            <Form.Label>Фильтр по задолженности</Form.Label>
                            <Form.Control type="text" name="filterByArrears" onChange={handleChange} value={filters.filterByArrears}/>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={4} className="mt-4 mb-4">
                        <Form.Group controlId="owner">
                            <Form.Label>Фильтр по владельцу</Form.Label>
                            <Form.Control type="text" name="filterByOwner" onChange={handleChange} value={filters.filterByOwner}/>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={4} className="mt-4 mb-4">
                        <Form.Group controlId="onePlotNumber">
                            <Form.Label>Информация по номеру участка</Form.Label>
                            <Form.Control type="text" name="onePlotNumberInfo" onChange={() => { setOnePlotNumber(event.target.value) }} value={onePlotNumber}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12}/>
                    <Col md={6} lg={2} className="mb-4">
                        <Button variant="primary" onClick={resetFilters}>Сбросить фильтр</Button>
                    </Col>

                    <Col xs={12}/>

                    {
                        Number(onePlotNumber) <= 0 &&
                            <>
                                <Col md={6} lg={4} className="mt-4 mb-4">
                                    <Form.Group controlId="sortBy">
                                        <Form.Label>Сортировать</Form.Label>
                                        <Form.Control as="select" aria-label="Сортировка участков" name="sortBy"
                                                      value={filters.sortBy}
                                                      onChange={handleChange}>
                                            <option value="plotNumber">По номеру участка</option>
                                            <option value="arrears">По задолженности</option>
                                            <option value="owner">По владельцу</option>
                                            <option value="rate">По тарифу</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={4} className="mt-4 mb-4">
                                    <Form.Group controlId="order">
                                        <Form.Label>Сортировать</Form.Label>
                                        <Form.Control as="select" aria-label="Сортировка участков" name="order"
                                                      value={filters.order}
                                                      onChange={handleChange}>
                                            <option value="ASC">По возрастанию</option>
                                            <option value="DESC">По убыванию</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={4} className="mt-4 mb-4">
                                    <Form.Group controlId="limit">
                                        <Form.Label>Выводить по</Form.Label>
                                        <Form.Control as="select" aria-label="Сортировка участков" name="limit"
                                                      value={filters.limit}
                                                      onChange={handleChange}>
                                            <option value={10}>По 10</option>
                                            <option value={25}>По 25</option>
                                            <option value={50}>По 50</option>
                                            <option value={totalPlots}>Все {totalPlots}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </>
                    }
                    { isLoading &&
                    <div className="mt-4 mb-4 col-xs-12">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </Spinner>
                    </div>
                    }
                    <div className={error ? 'd-block mt-4 mb-4 col-xs-12' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
                    </div>
                    {Number(onePlotNumber) <= 0 && plotsToShow && totalPlots &&
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>Номер участка</th>
                            <th>Владелец</th>
                            <th>Тариф</th>
                            <th>Задолженность</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            plotsToShow.map((plot, key) => (
                                <tr key={key} onClick={() => {
                                  setOnePlotNumber(plot.plotNumber)
                                }}>
                                    <td>{plot.plotNumber}</td>
                                    <td>{plot.owner}</td>
                                    <td>{plot.rate}</td>
                                    <td>{plot.arrears}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>}

                    { Number(onePlotNumber) <= 0 && paginationItems[0] && totalPlots && totalPages > 1 &&
                    <Pagination>
                        <Pagination.First onClick={() => {
                          setActivePage(1)
                          setFilters({ ...filters, start: 0 })
                        }}/>
                        <Pagination.Prev onClick={() => {
                          setActivePage(activePage > 1 ? activePage - 1 : 1)
                          setFilters({ ...filters, start: activePage > 1 ? (activePage - 1) * filters.limit : 0 })
                        }}/>
                        {paginationItems}
                        <Pagination.Next onClick={() => {
                          setActivePage(activePage < totalPages ? activePage + 1 : totalPages)
                          setFilters({ ...filters, start: activePage < totalPages ? activePage * filters.limit : (totalPages - 1) * filters.limit })
                        }}/>
                        <Pagination.Last onClick={() => {
                          setActivePage(totalPages)
                          setFilters({ ...filters, start: (totalPages - 1) * filters.limit })
                        }}/>
                    </Pagination>
                    }
                    {
                        Number(onePlotNumber) > 0 &&
                        <Col xs={12} className="mt-4 mb-4">
                            <UpdatePlot plot={Number(onePlotNumber)}/>
                        </Col>
                    }
                </Row>
            </Container>
        </>
  )
}

export default GetAllPlots
