import React, { useRef, useState } from 'react'
import api from '../../Api'
import GetPayments from '../../Payments'
import { Button, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

function Payments () {
  const [personalData, setPersonalData] = useState(null)
  const [plotNumber, setPlotNumber] = useState(null)
  const selectRef = useRef(null)
  const [fetching, setFetching] = useState(false)
  const [cookies] = useCookies(['accessToken'])

  if (!personalData && !fetching) {
    setFetching(true)
    api
      .get('/user/getPersonalData', { Authorization: 'Bearer ' + cookies.accessToken })
      .then((data) => {
        setPersonalData(data)
      })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setPlotNumber(Number(selectRef.current.value))
  }

  return (
        <>
            <h1>История платежей</h1>
            { personalData && personalData.plots &&
                <div>
                    {
                        (personalData.plots.length === 1)
                          ? <GetPayments plot={personalData.plots[0]} token={cookies.accessToken}/>
                          : <div>
                            <Form className="text-left" onSubmit={handleSubmit}>
                                <Form.Group controlId="selectPlot">
                                    <Form.Label>
                                        Выберите участок
                                    </Form.Label>
                                    <Form.Control ref={selectRef} as="select">
                                        {
                                            personalData.plots.map((plot, key) => (
                                                <option key={key} value={plot}>{plot}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Показать
                                </Button>
                            </Form>
                            { plotNumber &&
                                <GetPayments plot={plotNumber} token={cookies.accessToken}/>
                            }
                        </div>
                    }
                </div>
            }
        </>
  )
}
export default Payments
