import React, { useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap'
import api, { parseError } from '../Api'
import PropTypes from 'prop-types'

GetPayments.propTypes = {
  plot: PropTypes.number,
  token: PropTypes.string
}
function GetPayments (props) {
  const [data, setData] = useState({})
  const [plotNumber, setPlotNumber] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  let fetching = false

  if (!data[props.plot] && !fetching && plotNumber !== props.plot) {
    fetching = true
    setPlotNumber(props.plot)
    setError(false)
    setIsLoading(true)
    api
      .post('/user/getPayments', {
        plotNumber: props.plot
      }, {
        Authorization: 'Bearer ' + props.token
      })
      .then((response) => {
        setData({
          ...data,
          [props.plot]: response
        })
        setIsLoading(false)
        fetching = false
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setIsLoading(false)
        fetching = false
      })
  }

  return (
        <>
            <h2>История платежей по участку {props.plot}</h2>
            { isLoading &&
            <div>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            </div>
            }
            <div className={error ? 'd-block' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
            </div>
            <Container fluid>
                <Row>
                    { data[props.plot] &&
                    <Table bordered hover>
                        <thead>
                        <tr>
                        <th>Дата платежа</th>
                        <th>Сумма</th>
                        <th>Плательщик</th>
                        <th>Назначение платежа</th>
                        </tr>
                        </thead>
                        <tbody>
                    {
                        data[props.plot].map((payment, key) => (
                        <tr key={key}>
                        <td>{payment.date}</td>
                        <td>{payment.value}</td>
                        <td>{payment.payer}</td>
                        <td>{payment.description}</td>
                        </tr>
                        ))
                    }
                        </tbody>
                        </Table>
                    }
                </Row>
            </Container>
        </>
  )
}

export default GetPayments
