import React, { useEffect, useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap'
import api, { parseError } from '../Api'
import PropTypes from 'prop-types'

GetRates.propTypes = {
  plot: PropTypes.number,
  token: PropTypes.string,
  needUpdate: PropTypes.bool
}
function GetRates (props) {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!isLoading && props.needUpdate) {
      setIsLoading(true)
      setError(false)
      api
        .post('/user/getRates', {
          plotNumber: props.plot
        }, {
          Authorization: 'Bearer ' + props.token
        })
        .then((response) => {
          setData({
            ...data,
            [props.plot]: response
          })
          setIsLoading(false)
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setIsLoading(false)
        })
    }
  })

  return (
        <>
            <h2>Тарифы по участку {props.plot}</h2>
            { isLoading &&
            <div>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            </div>
            }
            <div className={error ? 'd-block' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
            </div>
            <Container fluid>
                <Row>
                    { data[props.plot] &&
                    <Table bordered hover>
                        <thead>
                        <tr>
                        <th>Тариф</th>
                        <th>Статус</th>
                        <th>Дата начала действия</th>
                        <th>Дата окончания действия</th>
                        </tr>
                        </thead>
                        <tbody>
                    {
                        data[props.plot].map((rate, key) => (
                        <tr key={key}>
                        <td>{rate.value}</td>
                        <td>{rate.status}</td>
                        <td>{rate.startDate}</td>
                        <td>{rate.endDate}</td>
                        </tr>
                        ))
                    }
                        </tbody>
                        </Table>
                    }
                </Row>
            </Container>
        </>
  )
}

export default GetRates
