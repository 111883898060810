import React, { useEffect, useState } from 'react'
import api from '../../../Api/Api'
import { useCookies } from 'react-cookie'
import { parseError } from '../../../Api'
import { Container, Row, Spinner, Table } from 'react-bootstrap'

function GetMeters () {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies] = useCookies(['accessToken'])
  const [data, setData] = useState([])

  useEffect(() => {
    if (Object.keys(data).length === 0 && !isLoading && !error) {
      setIsLoading(true)
      setError(false)
      api
        .get('/user/getMeters', {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((response) => {
          setData(response)
          setIsLoading(false)
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setIsLoading(false)
        })
    }
  })
  return (
        <>
            <h2>Счётчики</h2>
            { isLoading &&
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>
            }
            <div className={error ? 'd-block' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
            </div>
            <Container fluid>
                <Row>
                    { data &&
                        <Table bordered hover>
                            <thead>
                            <tr>
                                <th>Счётчик</th>
                                <th>Текущий тариф</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map((meter, key) => (
                                    <tr key={key}>
                                        <td>{meter.meter}</td>
                                        <td>{meter.rate}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    }
                </Row>
            </Container>
        </>
  )
}
export default GetMeters
