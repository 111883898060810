import React, { useContext } from 'react'
import styles from './Footer.module.css'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { AppContext } from '../../App'

function Footer () {
  const year = (new Date()).getFullYear()
  const { setRightColumnBlock } = useContext(AppContext)
  return (
        <>
            <div className={styles.footer}>
                <Navbar bg="dark" variant="dark">
                    <Container fluid className={styles.container}>
                        <Navbar.Brand href="https://agro-dar.com/">&#169; 2008 - { year } ДНП &quot;Агро-Дар&quot;</Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link href="#" onClick={(e) => {
                              e.preventDefault()
                              setRightColumnBlock('privacy')
                            }}>Политика конфиденциальности</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </>
  )
}

export default Footer
