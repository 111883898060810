import React, { useContext, useEffect, useState } from 'react'
import GetPublicPosts from '../../Posts/GetPublicPosts'
import { Container, Row } from 'react-bootstrap'
import { AppContext } from '../../App'
import PublicPostShort from '../../Posts/PublicPostShort'
import PublicPost from '../../Posts/PublicPost'

function News () {
  const [posts, setPosts] = useState(null)
  const [arePostsLoading, setPostsLoading] = useState(false)
  const { postCategory, singlePost, setSinglePost } = useContext(AppContext)
  useEffect(() => {
    setSinglePost(null)
  }, [postCategory])

  if (!arePostsLoading && !posts) {
    setPostsLoading(true)
    GetPublicPosts()
      .then((result) => {
        setPosts(result)
      })
  }

  return (
      <>
        {
          !singlePost &&
          <Container fluid>
            <h2>Информация для жителей</h2>
            <h3 className="mt-2 mb-2 text-secondary">{postCategory}</h3>

          {
            posts && postCategory !== 'Все статьи' &&
          <Row>
            {posts.filter((post) => {
              return (post.category === postCategory) ? post : null
            }).map((post, key) => (
                <div className="col-md-6 col-lg-4" key={key}>
                  <PublicPostShort post={post}/>
                </div>
            ))}
          </Row>
          }

          {
            posts && postCategory === 'Все статьи' &&
          <Row>
            {posts.map((post, key) => (
                <div className="col-md-6 col-lg-4" key={key}>
                  <PublicPostShort post={post}/>
                </div>
            ))}
          </Row>
          }
        </Container>
        }

        {
          singlePost &&
        <Container fluid>
          <Row>
            <PublicPost post={singlePost}/>
          </Row>
        </Container>
        }
      </>
  )
}
export default News
