import React, { useState } from 'react'
import api, { parseError } from '../../Api'
import { Button, Form, FormText } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import GetAndShowImage from '../../Files/GetAndShowImage'
import { useCookies } from 'react-cookie'

function UpdatePost () {
  const [allPosts, setAllPosts] = useState(null)
  const [data, setData] = useState(null)
  const [textToSend, setTextToSend] = useState(null)
  const [error, setError] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cookies] = useCookies(['accessToken'])
  const token = cookies.accessToken
  const [buttonActive, setButtonActive] = useState(true)

  if (!uploaded && !isLoading) {
    setError(null)
    setIsLoading(true)
    api
      .get('/admin/getAllPosts',
        {
          Authorization: 'Bearer ' + token
        })
      .then((response) => {
        response.sort(function (a, b) {
          return a.postNumber - b.postNumber
        })
        setAllPosts(response)
        setData(response[0])
        setUploaded(true)
        setIsLoading(false)
      })
      .catch(async (error) => {
        setError(await parseError(error))
      })
  }
  const handleSelectChange = (event) => {
    setData(allPosts[Number(event.target.value)])
  }

  const handleChange = (event) => {
    const input = event.target
    setData({
      ...data,
      [input.name]: (input.name === 'image' || input.name === 'images') ? input.files : input.value

    })
  }
  const handleSubmitUpdate = (event) => {
    event.preventDefault()
    setError(null)
    setIsLoading(true)
    const sendData = new FormData()

    sendData.append('image[0]', data.image[0])

    if (data.images) {
      for (let i = 0; i < data.images.length; i++) { sendData.append(`images[${i}]`, data.images[i]) }
    }
    sendData.append('header', data.header)
    sendData.append('postNumber', data.postNumber)

    sendData.append('text', (textToSend) || data.text)
    sendData.append('category', data.category)
    sendData.append('status', data.status)
    api
      .post('/admin/updatePost',
        sendData,
        {
          Authorization: 'Bearer ' + token
        },
        'files')
      .then((response) => {
        setButtonActive(true)
        setAllPosts(allPosts.map(object => {
          return (object.postNumber === response.postNumber) ? response : object
        }))
        setData(response)
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setButtonActive(true)
        setUploaded(false)
        setIsLoading(false)
      })
  }
  return (
      <>
        <h1>Редактировать статью</h1>
          { error &&
             <div className='text-danger'>{error}</div>
          }
        {
            allPosts &&
              <Form.Control as="select" onChange={handleSelectChange}>
                  {
                      allPosts.map((post, key) => (
                          <option key={key} value={key}>Статья №{post.postNumber} {post.header}</option>
                      ))
                  }
              </Form.Control>
        }
          { data &&
                  <>
                      <h2 className="text-left mt-5 mb-3">Статья №{data.postNumber}</h2>
                      <Form id="updatePostForm" className="text-left" method="post" onSubmit={handleSubmitUpdate} encType="multipart/form-data">
                          <div className="col-sm-4 col-xs-6 pl-0">
                              <GetAndShowImage fileName={data.image} altText={data.header}/>
                          </div>
                          <Form.Group controlId="post-image">
                              <Form.Label>Главное изображение для статьи</Form.Label>
                              <Form.Control type="file" name="image" onChange={handleChange} accept="image/*"/>
                          </Form.Group>
                          {
                              data.images && Array.isArray(data.images) &&
                              <div className="row">
                                <hr className="w-100"/>
                                {data.images.map((additionalImage, key) => (
                                    <div className="col-sm-4 col-xs-6" key={key}>
                                      <GetAndShowImage fileName={additionalImage} altText={data.header + key}/>
                                    </div>
                                ))}
                              </div>
                          }
                          <Form.Group controlId="post-images">
                              <Form.Label>Дополнительные изображения</Form.Label>
                              <Form.Control type="file" name="images" multiple onChange={handleChange} accept="image/*"/>
                          </Form.Group>
                          <Form.Group controlId="post-header">
                              <Form.Label>Заголовок</Form.Label>
                              <Form.Control type="text" name="header" required onChange={handleChange} value={data.header}/>
                          </Form.Group>
                          <Form.Group controlId="post-category">
                              <Form.Label>Категория</Form.Label>
                              <Form.Control type="text" name="category" required onChange={handleChange} value={data.category}/>
                          </Form.Group>
                          <Form.Group controlId="post-text">
                              <Form.Label>Текст статьи</Form.Label>
                              <CKEditor
                                  editor={ ClassicEditor }
                                  data={data.text}
                                  onChange={ (event, editor) => {
                                    setTextToSend(editor.getData())
                                  } }
                              />
                          </Form.Group>
                          <Form.Group>
                              <FormText>
                                  Статус статьи
                              </FormText>
                              <Form.Check id="post-status-private">
                                  <Form.Check.Input type="radio" name="status" checked={data.status === 'private_status'} value="private_status" onChange={handleChange}/>
                                  <Form.Check.Label>Приватный</Form.Check.Label>
                              </Form.Check>
                              <Form.Check id="post-status-public">
                                  <Form.Check.Input type="radio" name="status" checked={data.status === 'public_status'} value="public_status" onChange={handleChange}/>
                                  <Form.Check.Label>Публичный</Form.Check.Label>
                              </Form.Check>
                          </Form.Group>
                          <Button variant="primary" type="submit" disabled={!buttonActive}>
                              Обновить
                          </Button>
                      </Form>
                  </>
          }
      </>
  )
}

export default UpdatePost
