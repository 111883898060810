import React, { useState } from 'react'
import { Button, Form, FormText } from 'react-bootstrap'
import api, { parseError, parseErrors } from '../../Api'
import { useCookies } from 'react-cookie'

function SetArrearsAndRatesFromFile () {
  const [formData, setFormData] = useState({
    accessToken: '',
    file: new FormData()
  })
  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)
  const [cookies] = useCookies(['accessToken'])

  const handleChange = () => {
    setFormData({
      accessToken: cookies.accessToken,
      file: new FormData(document.getElementById('SetArrearsAndRatesFromFileForm'))
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors({})
    setError(null)

    api
      .post('/admin/setArrearsAndRatesFromFile',
        formData.file,
        {
          Authorization: 'Bearer ' + formData.accessToken
        }, 'file')
      .then((response) => {
        setButtonActive(true)
        setData(response)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
      })
  }

  return (
        <>
        <h1 className="mt-4 mb-3">Загрузить тарифы и задолженности участков из файла</h1>
    <Form id="SetArrearsAndRatesFromFileForm" className="text-left" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
        <Form.Group className={error ? 'd-block' : 'd-none'}>
            <FormText className="h5 alert alert-danger">
                {error}
            </FormText>
        </Form.Group>
        <Form.Group className={data ? 'd-block' : 'd-none'}>
            <FormText className="h5 alert alert-success">
               Успешно загружено!
            </FormText>
        </Form.Group>
        <Form.Group>
            <Form.Label>Файл в формате csv (номер участка, разделитель &apos;;&apos; ,тариф участка, разделитель &apos;;&apos;, задолженность)</Form.Label>
            <Form.Control type="file" name="file" onChange={handleChange} required/>
        </Form.Group>
        <Form.Text className="text-danger">
            {errors.file}
        </Form.Text>
        <Button variant="primary" type="submit" disabled={!buttonActive}>
            Отправить
        </Button>
    </Form>

        </>
  )
}

export default SetArrearsAndRatesFromFile
