import React, { useState } from 'react'
import { Button, Form, FormText, Spinner, Table } from 'react-bootstrap'
import api, { parseError, parseErrors } from '../../Api'
import { CSVLink } from 'react-csv'
import { useCookies } from 'react-cookie'

function CreateUsersFromFile () {
  const [formData, setFormData] = useState({
    accessToken: '',
    file: new FormData()
  })
  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [cookies] = useCookies(['accessToken'])

  const handleChange = () => {
    setFormData({
      accessToken: cookies.accessToken,
      file: new FormData(document.getElementById('createUsersFromFileForm'))
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors({})
    setError(null)
    setIsLoading(true)

    api
      .post('/admin/createUsersFromFile',
        formData.file,
        {
          Authorization: 'Bearer ' + formData.accessToken
        }, 'file')
      .then((response) => {
        setButtonActive(true)
        setData(response)
        setIsLoading(false)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
        setIsLoading(false)
      })
  }

  return (
        <>
        <h1 className="mt-4 mb-3">Создать пользователей из файла</h1>
    <Form id="createUsersFromFileForm" className="text-left" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
        <Form.Group className={error ? 'd-block' : 'd-none'}>
            <FormText className="h5 alert alert-danger">
                {error}
            </FormText>
        </Form.Group>
        <Form.Group>
            <Form.Label>Файл в формате csv (номер участка, разделитель ; , ФИО)</Form.Label>
            <Form.Control type="file" name="file" onChange={handleChange} required/>
        </Form.Group>
        <Form.Text className="text-danger">
            {errors.file}
        </Form.Text>
        <Button variant="primary" type="submit" disabled={!buttonActive}>
            Отправить
        </Button>
    </Form>
            { isLoading &&
            <div>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            </div>
            }
            { data &&
            <Table bordered hover className="mt-3">
                <thead>
                <tr>
                    <th>Владелец</th>
                    <th>Номер(-а) участка(-ов)</th>
                    <th>Пароль</th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((plots, key) => (
                        <tr key={key}>
                            <td>{plots.owner}</td>
                            <td>
                                {
                                plots.plots.map((plot, key) => (
                                <span key={key + 10000}>
                                 <span>{plot}</span>
                            { (key !== plots.plots.length - 1) &&
                                <span>,</span>
                            }
                                </span>
                                ))

                            }</td>
                            <td>{plots.password}</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table> &&
                <>
                    <CSVLink data={data} className="mt-4 text-left">Выгрузить таблицу</CSVLink>;
                </>
            }
        </>
  )
}

export default CreateUsersFromFile
