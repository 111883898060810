import React, { createContext, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Home'
import NotFound from './NotFound'
import jwtDecode from 'jwt-decode'
import api from './Api'
import { useCookies } from 'react-cookie'

export const AppContext = createContext(null)

function App () {
  const [cookies, , removeCookie] = useCookies(['accessToken'])
  const [isUserLogged, setIsUserLogged] = useState(!!cookies.accessToken)
  const [postCategory, setPostCategory] = useState('Все статьи')
  const [rightColumnBlock, setRightColumnBlock] = useState('news')
  const [cabinetLink, setCabinetLink] = useState('')
  const [singlePost, setSinglePost] = useState(null)
  const [showHello, setShowHello] = useState(true)
  const [userRole, setUserRole] = useState('user')

  const logOut = () => {
    setUserRole('user')
    removeCookie('accessToken')
    setIsUserLogged(false)
  }

  useEffect(() => {
    if (isUserLogged) {
      try {
        const token = atob(cookies.accessToken)
        setUserRole(jwtDecode(token.toString()).role)
        api
          .get('/auth/validateAccessToken', {
            Authorization: 'Bearer ' + cookies.accessToken
          })
          .catch(() => {
            logOut()
          })
      } catch (e) {
        logOut()
      }
    }
  }, [cookies.accessToken])

  return (
        <BrowserRouter>
            <div className="App">
                <AppContext.Provider value={{ isUserLogged, setIsUserLogged, postCategory, setPostCategory, rightColumnBlock, setRightColumnBlock, cabinetLink, setCabinetLink, userRole, singlePost, setSinglePost, showHello, setShowHello }}>
                <Routes>
                    <Route exact path='/' element={<Home/>} />
                    <Route exact path='*' element={<NotFound />} />
                </Routes>
                </AppContext.Provider>
            </div>
        </BrowserRouter>
  )
}

export default App
