import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, FormText } from 'react-bootstrap'
import api, { parseError } from '../../../Api'
import { useCookies } from 'react-cookie'

function SendMetersData () {
  const [plots, setPlots] = useState([])
  const [dateFromServer, setDateFromServer] = useState({})
  useEffect(() => {
    if (!plots.length) {
      api
        .get('/user/getPersonalData', {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((data) => {
          setPlots(data.plots)
        })
    }
  })
  useEffect(() => {
    if (!Object.keys(dateFromServer).length) {
      api
        .get('/user/getDateFromServer', {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((data) => {
          setDateFromServer(data)
        })
    }
  })
  const [data, setData] = useState(null)
  const [buttonActive, setButtonActive] = useState(true)
  const [cookies] = useCookies(['accessToken'])
  const [error, setError] = useState(false)
  const electricityNight = useRef(null)
  const electricityDay = useRef(null)
  const plot = useRef(null)
  const months = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setError(null)
    setData(null)

    api
      .post('/user/sendMetersData',
        {
          plot: parseInt(plot.current.value),
          electricityDay: parseInt(electricityDay.current.value),
          electricityNight: parseInt(electricityNight.current.value)
        },
        {
          Authorization: 'Bearer ' + cookies.accessToken
        })
      .then((response) => {
        setButtonActive(true)
        setData(response)
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setButtonActive(true)
      })
  }
  return (
        <>
            <div className="text-left">
                <h2 className="mb-5">Передать показания { months[dateFromServer.month] } -  { dateFromServer.year }</h2>
                <Form className="text-left mt-4" onSubmit={handleSubmit} method="post">
                    <Form.Group className={error ? 'd-block' : 'd-none'}>
                        <FormText className="h5 alert alert-danger">
                            {error}
                        </FormText>
                    </Form.Group>
                    <Form.Group className={data ? 'd-block' : 'd-none'}>
                        <FormText className="h5 alert alert-success">
                            Успешно загружено!
                        </FormText>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="h5">Номер участка:</Form.Label>
                        <Form.Control as="select" aria-label="Номер участка" name="plot" ref={plot}>
                            {
                                plots.map((plot, key) => (
                                    <option key={key} value={plot}>Участок {plot}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="h5">Электричество День:</Form.Label>
                        <Form.Control type="number" placeholder="00" name="electricityDay" ref={electricityDay}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="h5">Электричество Ночь:</Form.Label>
                        <Form.Control type="number" placeholder="00" name="electricityNight" ref={electricityNight}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={!buttonActive}>
                        Отправить
                    </Button>
                </Form>
            </div>
        </>
  )
}

export default SendMetersData
