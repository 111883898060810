import React, { useEffect, useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap'
import api, { parseError } from '../../Api'
import { useCookies } from 'react-cookie'

function GetMetersDataFullArrears () {
  const [data, setData] = useState({})
  const [plots, setPlots] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies] = useCookies(['accessToken'])

  useEffect(() => {
    if (Object.keys(data).length === 0 && error === false) {
      setError(false)
      setIsLoading(true)
      api
        .get('/user/getMetersDataFullArrears', {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((response) => {
          setData(response)
          setPlots(response.plots)
          setIsLoading(false)
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setIsLoading(false)
        })
    }
  })

  return (
        <>
            <h2>Задолженность по ЭЭ</h2>
            { isLoading &&
            <div className="d-flex justify-content-center align-items-center">
                <span className="sr-only px-1">Загрузка...</span>
                <Spinner animation="border" role="status"/>
            </div>
            }
            <div className={error ? 'd-block' : 'd-none'}>
                <span className="h5 alert alert-danger">
                    {error}
                </span>
            </div>
            <Container fluid>
                <Row>
                    { plots &&
                      <div>
                        { Object.keys(plots).map((plot) => (
                          <div className="mt-3 mb-5" key={plot}>
                            <h3>Участок {plot}</h3>
                            <Table bordered hover>
                              <thead>
                              <tr>
                                <th>Счётчик</th>
                                <th>Задолженность</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td>Электричество День</td>
                                <td>{plots[plot]['Электричество День']}</td>
                              </tr>
                              <tr>
                                <td>Электричество Ночь</td>
                                <td>{plots[plot]['Электричество Ночь']}</td>
                              </tr>
                              </tbody>
                            </Table>
                            <div>
                              <h3>Текущая задолженность  участка { plot } : {plots[plot].totalArrears} руб.</h3>
                            </div>
                            { (Number(plots[plot].totalArrears.replace(/\s/g, '')) > 0) &&
                              <div className="text-start">
                                <p>
                                  <h4>Реквизиты для оплаты</h4>
                                  Наименование: СНТСН &ldquo;АГРО-ДАР&rdquo;<br/>
                                  ИНН: 5038063810<br/>
                                  КПП: 503801001<br/>
                                  ОГРН: 1085038009132<br/>
                                  Расчётный счёт: 40703810540000400687<br/>
                                  Наименование: ПАО Сбербанк<br/>
                                  БИК: 044525225<br/>
                                  Корсчёт: 30101810400000000225<br/>
                                  Назначение платежа: Оплата электроэнергии. Участок { plot }
                                </p>
                                <h5 className="mt-3">Оплатить из мобильного приложения банка</h5>
                                <img src={plots[plot].qr} alt="Qr-код на оплату"/>
                              </div>
                            }
                          </div>
                        ))
                        }
                      </div>
                    }

                </Row>
            </Container>
        </>
  )
}

export default GetMetersDataFullArrears
