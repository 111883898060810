import React, { useContext, useState } from 'react'
import { Button, Form, FormText } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { parseError, parseErrors } from '../../Api'
import api from '../../Api/Api'
import { AppContext } from '../../App'
import { useCookies } from 'react-cookie'

/**
 * @TODO refactor Component split to simple methods
 */
function LoginForm (setShowForm) {
  const [formData, setFormData] = useState({
    login: '',
    password: '',
    phone: '',
    phoneConfirm: '',
    phoneValidateCode: '',
    agree: false
  })
  const [, setCookie, removeCookie] = useCookies(['accessToken'])
  const [buttonActive, setButtonActive] = useState(true)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState(null)
  const [phone, setPhone] = useState(false)
  const [phoneCode, setPhoneCode] = useState(false)
  const { setIsUserLogged, setRightColumnBlock } = useContext(AppContext)

  const setToken = (token) => {
    removeCookie('accessToken')
    setCookie('accessToken', token, { maxAge: 86400, sameSite: 'strict', secure: true })
    setIsUserLogged(true)
  }

  const countDigits = (n) => {
    let i = 0
    for (; n > 1; i++) {
      n /= 10
    }
    return i
  }

  const getNumberFromString = (str) => {
    return Number(str.replace(/\D+/g, ''))
  }

  const handleChange = (event) => {
    const input = event.target
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!formData.agree) {
      setErrors({ agree: 'Прежде чем продолжить, Вы должны согласиться с политикой обработки данных!' })
      return
    }

    setButtonActive(false)
    setErrors({})
    setError(null)
    setPhone(false)
    setPhoneCode(false)

    api
      .post('/auth/authAction', {
        login: formData.login,
        password: formData.password
      })
      .then((response) => {
        setShowForm(false)
        setButtonActive(true)
        setToken(response)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        const errorMessage = await parseError(error)
        if (errorMessage === 'Необходимо добавить телефон!') { setPhone(true) }
        setError(errorMessage)
        setButtonActive(true)
      })
  }

  const handleValidatePhone = (event) => {
    event.preventDefault()
    if (!formData.agree) {
      setErrors({ agree: 'Прежде чем продолжить, Вы должны согласиться с политикой обработки данных!' })
      return
    }

    if (countDigits(getNumberFromString(formData.phone)) !== 11) {
      setErrors({ phone: 'Некорректное значение номера!Проверьте введённые данные!' })
      return
    }

    if (formData.phone !== formData.phoneConfirm) {
      setErrors({ phoneConfirm: 'Номера не совпадают!Проверьте введённые данные!' })
      return
    }

    setErrors({})
    setError(null)
    setPhone(true)
    setPhoneCode(false)

    api
      .post('/auth/validatePhone', {
        phone: getNumberFromString(formData.phone),
        login: formData.login,
        password: formData.password
      })
      .then(() => {
        setButtonActive(true)
        setPhone(false)
        setPhoneCode(true)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
        setPhone(true)
        setPhoneCode(false)
      })
  }

  const handleValidatePhoneCode = (event) => {
    event.preventDefault()
    if (!formData.agree) {
      setErrors({ agree: 'Прежде чем продолжить, Вы должны согласиться с политикой обработки данных!' })
      return
    }
    if (countDigits(formData.phoneValidateCode) !== 4) {
      setErrors({ phoneValidateCode: 'Некорректное значение кода!Проверьте введённые данные!' })
      return
    }
    setErrors({})
    setError(null)
    setPhone(false)
    setPhoneCode(true)

    api
      .post('/auth/validatePhoneCode', {
        phoneValidateCode: getNumberFromString(formData.phoneValidateCode),
        login: formData.login,
        password: formData.password
      })
      .then((response) => {
        setShowForm(false)
        setButtonActive(true)
        setPhone(false)
        setPhoneCode(false)
        setToken(response)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
        setPhone(true)
        setPhoneCode(false)
      })
  }

  return (
        <Form method="post" onSubmit={handleSubmit}>
            <Form.Group className={error ? 'd-block' : 'd-none'}>
                <FormText className="h5 alert alert-danger">
                    {error}
                </FormText>
            </Form.Group>
            <Form.Group controlId="formLogin" className={(phone || phoneCode) ? 'd-none' : 'd-block'}>
                <Form.Label>Логин</Form.Label>
                <Form.Control type="text" placeholder="Мой логин" name="login" value={formData.login}
                              onChange={handleChange}
                              required/>
                <Form.Text className="text-danger">
                    {errors.login}
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formPassword" className={(phone || phoneCode) ? 'd-none' : 'd-block'}>
                <Form.Label>Пароль</Form.Label>
                <Form.Control type="password" placeholder="Пароль" name="password" value={formData.password}
                              onChange={handleChange}
                              required/>
                <Form.Text className="text-danger">
                    {errors.password}
                </Form.Text>
            </Form.Group>

            {phone &&
                <>
                    <Form.Group controlId="formPhone">
                        <Form.Label>Телефон</Form.Label>
                        <InputMask id="formPhone" className="form-control" type="text" placeholder="+7 (999) 999-99-99" mask="+7 (999) 999-99-99" name="phone" value={formData.phone}
                                      onChange={handleChange}
                                      required/>
                        <Form.Text className="text-danger">
                            {errors.phone}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formPhoneConfirm">
                        <Form.Label>Подтвердите телефон</Form.Label>
                        <InputMask id="formPhoneConfirm" className="form-control" type="text" placeholder="+7 (999) 999-99-99" mask="+7 (999) 999-99-99" name="phoneConfirm" value={formData.phoneConfirm}
                                      onChange={handleChange}
                                      required/>
                        <Form.Text className="text-danger">
                            {errors.phoneConfirm}
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" onClick={handleValidatePhone} disabled={!buttonActive}>
                        Отправить
                    </Button>
                </>
            }

            {phoneCode &&
            <>
                <Form.Group controlId="formPhoneValidateCode">
                    <Form.Label>Проверочный код</Form.Label>
                    <Form.Control type="text" placeholder="Проверочный код" name="phoneValidateCode" value={formData.phoneValidateCode}
                                  onChange={handleChange}
                                  required/>
                    <Form.Text className="text-danger">
                        {errors.phoneValidateCode}
                    </Form.Text>
                </Form.Group>
                <Button variant="primary" onClick={handleValidatePhoneCode} disabled={!buttonActive}>
                    Подтвердить
                </Button>
            </>
            }

            {!phoneCode &&
            <>
            <Form.Group controlId="formCheckbox" className="d-flex justify-content-start align-items-center">
                <Form.Check type="checkbox" className="d-inline mr-2" name="agree" checked={formData.agree}
                            onChange={handleChange}
                            required/>
                <Form.Label className="mb-0">Я <a href='#' className="text-danger" onClick={(e) => {
                  e.preventDefault()
                  setShowForm(false)
                  setRightColumnBlock('privacy')
                }}>прочитал</a> и согласен с политикой обработки данных</Form.Label>
            </Form.Group>
            <Form.Text className="text-danger">
                {errors.agree}
            </Form.Text>
            </>
            }

            <Button variant="primary" type="submit" disabled={!buttonActive} className={(phone || phoneCode) ? 'd-none' : 'd-block'}>
                Отправить
            </Button>
        </Form>
  )
}

export default LoginForm
