import React, { useState, useEffect } from 'react'
import api from '../../Api'
import { Container, Row, Table } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

function PersonalData () {
  const [personalData, setPersonalData] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [cookies] = useCookies(['accessToken'])
  useEffect(() => {
    if (!personalData && !fetching) {
      setFetching(true)
      api
        .get('/user/getPersonalData', {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((data) => {
          setPersonalData(data)
        })
    }
  })
  return (
        <>
            <h1 className="mb-3">Личные данные</h1>
            {personalData &&
                <Container fluid>
                    <Row>
                        <Table bordered hover>
                            <tbody>
                            <tr>
                                <td>ФИО</td>
                                <td>{personalData.owner}</td>
                            </tr>
                            {personalData.login &&
                                <tr>
                                    <td>Логин</td>
                                    <td>{personalData.login}</td>
                                </tr>
                            }
                            <tr>
                                <td>Телефон</td>
                                <td>{personalData.phone}</td>
                            </tr>
                            <tr>
                                {
                                    (personalData.plots.length > 1)
                                      ? <td>Номера участков</td>
                                      : <td>Номер участка</td>
                                }
                                <td>
                                    {
                                        personalData.plots.map((plot, key) => (
                                            <span key={key}>
                                    <span>{plot}</span>
                                                {(key !== personalData.plots.length - 1) &&
                                                    <span>,</span>
                                                }
                                </span>
                                        ))
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Container>
            }
        </>
  )
}

export default PersonalData
