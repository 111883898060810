import React, { useState } from 'react'
import api from '../../Api'
import { Container, Row, Table } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

function Arrears () {
  const [cookies] = useCookies(['accessToken'])
  const [data, setData] = useState(null)
  const [fetching, setFetching] = useState(false)
  if (!data && !fetching) {
    setFetching(true)
    api
      .get('/user/getArrears', {
        Authorization: cookies.accessToken
      })
      .then((data) => {
        setData(data)
      })
  }

  return (
        <>
            <h1>Текущая задолженность</h1>
            <Container fluid>
                <Row>
                    { data &&
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>Номер участка</th>
                            <th>Текущий тариф</th>
                            <th>Задолженность</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((arrears, key) => (
                                <tr key={key}>
                                    <td>{arrears.plot}</td>
                                    <td>{arrears.rate}</td>
                                    <td>{arrears.arrears}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                    }
                </Row>
            </Container>
        </>
  )
}
export default Arrears
