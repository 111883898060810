import React, { useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import PublicPostShort from '../../Posts/PublicPostShort'
import { AppContext } from '../../App'
import PublicPost from '../../Posts/PublicPost'
import api from '../../Api'
import { useCookies } from 'react-cookie'

function PrivateNews () {
  const [cookies] = useCookies(['accessToken'])
  const [posts, setPosts] = useState(null)
  const { singlePost } = useContext(AppContext)
  const [arePostsLoading, setPostsLoading] = useState(false)

  if (!arePostsLoading && !posts) {
    setPostsLoading(true)
    api.get('/posts/getPrivatePosts', {
      Authorization: 'Bearer ' + cookies.accessToken
    })
      .then((result) => {
        setPosts(result)
      })
  }

  return (
        <Container fluid>
            { posts && !singlePost &&
            <Row>
              <div className='col-12'>
                <h2>Частная информация</h2>
              </div>
                {posts.map((post, key) => (
                    <div className="col-md-6 col-lg-4" key={key}>
                        <PublicPostShort post={post}/>
                    </div>
                ))}
            </Row>
            }
          {
            singlePost &&
            <Row>
                <PublicPost post={singlePost}/>
            </Row>
          }
        </Container>
  )
}
export default PrivateNews
