import React from 'react'
import LeftColumn from '../Blocks/LeftColumn'
import TopColumn from '../Blocks/TopColumn'
import RightColumn from '../Blocks/RightColumn'
import { Col, Container, Row } from 'react-bootstrap'
import HelloColumn from '../Blocks/HelloColumn'
import Footer from '../Blocks/Footer'

function Home () {
  return (
      <>
          <Container fluid>
              <Row>
                  <Col lg={2} md={4} className="text-left pl-0 pr-0"><LeftColumn/></Col>
                  <Col lg={10} md={8}>
                      <Row>
                          <Col xs={12} className="text-right pl-0 pr-0"><TopColumn/></Col>
                          <Col xs={12} className="text-left mt-3 mb-2"><HelloColumn/></Col>
                          <Col xs={12}><RightColumn/></Col>
                      </Row>
                  </Col>
              </Row>
          </Container>
          <Footer/>
      </>
  )
}
export default Home
