import React, { useState } from 'react'
import { Button, Form, FormText, Spinner } from 'react-bootstrap'
import api, { parseError } from '../../Api'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useCookies } from 'react-cookie'

function CreatePost () {
  const [cookies] = useCookies(['accessToken'])
  const [formData, setFormData] = useState({
    accessToken: cookies.accessToken,
    postImage: '',
    postImages: [],
    postHeader: '',
    postText: '',
    postCategory: '',
    postStatusIsPublic: 0
  })
  const [buttonActive, setButtonActive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [uploaded, setUploaded] = useState(null)
  const [error, setError] = useState(false)

  const handleChange = (event) => {
    const input = event.target
    setFormData({
      ...formData,
      [input.name]: (input.name === 'postImage' || input.name === 'postImages') ? input.files : input.value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setError(null)
    setIsLoading(true)
    const sendFormData = new FormData()
    sendFormData.append('postImage[0]', formData.postImage[0])

    for (let i = 0; i < formData.postImages.length; i++) { sendFormData.append(`postImages[${i}]`, formData.postImages[i]) }

    sendFormData.append('postHeader', formData.postHeader)
    sendFormData.append('postText', formData.postText)
    sendFormData.append('postCategory', formData.postCategory)
    sendFormData.append('postStatusIsPublic', formData.postStatusIsPublic)
    api
      .post('/admin/createPost',
        sendFormData,
        {
          Authorization: 'Bearer ' + formData.accessToken
        },
        'files')
      .then((response) => {
        setButtonActive(true)
        setUploaded(response)
        setIsLoading(false)
      })
      .catch(async (error) => {
        setError(await parseError(error))
        setButtonActive(true)
        setUploaded(false)
        setIsLoading(false)
      })
  }

  return (
        <>
            <h1>Создать новость</h1>
            {(uploaded === true) &&
                <div>
                    <Button variant="success">Успешно загружено!</Button>
                </div>
            }
            {(uploaded === false) &&
                <div>
                    <Button variant="danger" className="mt-2 mb-2">Ошибка при загрузке!</Button>
                    <div className={error ? 'd-block' : 'd-none'}>
                        <h5 className="alert alert-danger">
                            {error}
                        </h5>
                    </div>
                </div>
            }
            { isLoading &&
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
               </div>
            }
            <Form id="createPostForm" className="text-left" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
                <Form.Group controlId="post-image">
                    <Form.Label>Главное изображение для статьи</Form.Label>
                    <Form.Control type="file" name="postImage" onChange={handleChange} required accept="image/*"/>
                </Form.Group>
                <Form.Group controlId="post-images">
                    <Form.Label>Дополнительные изображения</Form.Label>
                    <Form.Control type="file" name="postImages" multiple onChange={handleChange} accept="image/*"/>
                </Form.Group>
                <Form.Group controlId="post-header">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control type="text" name="postHeader" required onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId="post-category">
                    <Form.Label>Категория</Form.Label>
                    <Form.Control type="text" name="postCategory" required onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId="post-text">
                    <Form.Label>Текст статьи</Form.Label>
                    <CKEditor
                        editor={ ClassicEditor }
                        data=""
                        onChange={ (event, editor) => {
                          const data = editor.getData()
                          setFormData({
                            ...formData,
                            postText: data
                          })
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <FormText>
                        Статус статьи
                    </FormText>
                    <Form.Check id="post-status-private">
                        <Form.Check.Input type="radio" defaultChecked value="0" name="postStatusIsPublic" onChange={handleChange}/>
                        <Form.Check.Label>Приватный</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="post-status-public">
                        <Form.Check.Input type="radio" name="postStatusIsPublic" value="1" onChange={handleChange}/>
                        <Form.Check.Label>Публичный</Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={!buttonActive}>
                    Отправить
                </Button>
            </Form>
        </>
  )
}
export default CreatePost
