import React, { createContext, useContext, useRef, useState } from 'react'
import GetPublicPostsRubrics from '../../Posts/GetPublicPostsRubrics'
import { Image, Nav, Navbar } from 'react-bootstrap'
import { AppContext } from '../../App'
import CabinetLinks from '../CabinetLinks'
import styles from './LeftColumn.module.css'

export const logo = <Image alt="Коттеджный посёлок «Аляска»" src="/images/logo.png" className="img-fluid"/>

export const LeftColumnContext = createContext(null)

function LeftColumn () {
  const burgerButton = useRef(null)
  const [categories, setCategories] = useState([])
  const [areNewsLoading, setNewsLoading] = useState(false)
  const { isUserLogged, setPostCategory, setRightColumnBlock, setSinglePost, setShowHello } = useContext(AppContext)

  const setFilterPosts = (e, category) => {
    e.preventDefault()
    setRightColumnBlock('news')
    setPostCategory(category)
    setShowHello(true)
  }

  if (!areNewsLoading) {
    setNewsLoading(true)
    GetPublicPostsRubrics()
      .then((result) => {
        result.push('Все статьи')
        setCategories(result)
      })
  }

  return (
      <>
           <LeftColumnContext.Provider value={{ burgerButton }}>
            <Navbar className={'flex-column align-items-start ' + styles.mobileNav} expand="md">
              <Navbar.Toggle ref={burgerButton}/>
              <Navbar.Collapse className={'align-items-start ' + styles.mobileNavInner + ' ' + styles.maxWidth100}>
                <Nav className={'flex-column text-primary ' + styles.maxWidth100}>
                  <Nav.Link href="/" className={'text-center ' + styles.logo}>{logo}</Nav.Link>
                  <Nav.Link disabled className={'text-secondary ' + styles.linkBg}>Статьи</Nav.Link>
                  { categories &&
                    categories.map((category, key) => <Nav.Link onClick={(e) => {
                      burgerButton.current.click()
                      setSinglePost(false)
                      setFilterPosts(e, category)
                    }} key={key} className="ml-3 text-primary">{category}</Nav.Link>)
                  }
                  { isUserLogged &&
                    <CabinetLinks/>
                  }
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </LeftColumnContext.Provider>
      </>
  )
}

export default LeftColumn
