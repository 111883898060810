import React, { useContext } from 'react'
import News from '../News'
import { AppContext } from '../../App'
import Cabinet from '../Cabinet'
import Privacy from '../../Privacy'

function RightColumn () {
  const { rightColumnBlock } = useContext(AppContext)

  switch (rightColumnBlock) {
    case 'news':
      return (<News/>)
    case 'privacy':
      return (<Privacy/>)
    case 'cabinet':
      return (<Cabinet/>)
    default:
      return (<div/>)
  }
}
export default RightColumn
