import React, { useEffect, useState } from 'react'
import { Button, Form, FormText } from 'react-bootstrap'
import api, { parseError } from '../../Api'
import { useCookies } from 'react-cookie'
import GetPayments from '../../Payments'
import GetRates from '../../Rates'
import PropTypes from 'prop-types'

UpdatePlot.propTypes = {
  plot: PropTypes.number
}

function UpdatePlot (props) {
  const [error, setError] = useState(null)
  const [errorUpdate, setErrorUpdate] = useState(null)
  const [plotData, setPlotData] = useState(null)
  const [buttonActive, setButtonActive] = useState(true)
  const [cookies] = useCookies(['accessToken'])
  const formData = {
    accessToken: cookies.accessToken,
    plotNumber: props.plot
  }

  const [formDataUpdate, setFormDataUpdate] = useState({
    plotNumber: '',
    arrears: '',
    rate: '',
    owner: '',
    login: '',
    phone: '',
    role: ''
  })
  const [plotNumber, setPlotNumber] = useState(null)
  const [needUpdate, setNeedUpdate] = useState(false)

  const handleChangeUpdate = (event) => {
    const input = event.target
    setFormDataUpdate({
      ...formDataUpdate,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value
    })
  }
  useEffect(() => {
    if (props.plot !== 0) {
      setButtonActive(false)
      setError(null)
      setPlotNumber(formData.plotNumber)
      setErrorUpdate(null)
      setNeedUpdate(true)
      api
        .post('/admin/getPlotInfo', {
          plotNumber: formData.plotNumber
        },
        {
          Authorization: 'Bearer ' + cookies.accessToken
        })
        .then((response) => {
          setButtonActive(true)
          setNeedUpdate(false)
          setPlotData(response)
          setFormDataUpdate({
            ...formData,
            arrears: response.arrears.amount.toString(),
            rate: response.rate.amount.toString(),
            owner: response.owner,
            login: response.login,
            phone: response.phone,
            role: response.role
          })
        })
        .catch(async (error) => {
          setError(await parseError(error))
          setButtonActive(true)
          setNeedUpdate(false)
        })
    }
  }, [props.plot])

  const handleSubmitUpdate = (event) => {
    event.preventDefault()
    setButtonActive(false)
    setErrorUpdate(null)
    setNeedUpdate(true)
    api
      .post('/admin/updatePlotInfo', {
        plotNumber: formDataUpdate.plotNumber,
        arrears: formDataUpdate.arrears,
        rate: formDataUpdate.rate,
        owner: formDataUpdate.owner,
        login: formDataUpdate.login
      }, {
        Authorization: 'Bearer ' + cookies.accessToken
      })
      .then((response) => {
        setButtonActive(true)
        setNeedUpdate(false)
        setFormDataUpdate({
          ...formData,
          plotNumber: response.plotNumber,
          arrears: response.arrears.amount.toString(),
          rate: response.rate.amount.toString(),
          owner: response.owner,
          login: response.login,
          phone: response.phone,
          role: response.role
        })
      })
      .catch(async (error) => {
        setErrorUpdate(await parseError(error))
        setButtonActive(true)
        setNeedUpdate(false)
      })
  }

  return (
        <>
          <Form.Group className={error ? 'd-block' : 'd-none'}>
            <FormText className="h5 alert alert-danger">
              {error}
            </FormText>
          </Form.Group>
            {
                plotData && !error && plotNumber !== 0 &&
                <>
                    <h2 className="mt-4 mb-5">Информация по участку №{formDataUpdate.plotNumber}</h2>
                    <Form id="updatePostForm" className="text-left" method="post" onSubmit={handleSubmitUpdate}>
                        <Form.Group className={errorUpdate ? 'd-block' : 'd-none'}>
                            <FormText className="h5 alert alert-danger">
                                {errorUpdate}
                            </FormText>
                        </Form.Group>

                        <Form.Group controlId="formUpdateArrears">
                            <Form.Label>Текущая задолженность</Form.Label>
                            <Form.Control type="text" placeholder="Текущая задолженность" name="arrears"
                                          value={formDataUpdate.arrears}
                                          onChange={handleChangeUpdate}
                                          required/>
                        </Form.Group>
                        <Form.Group controlId="formUpdateRate">
                          <Form.Label>Тариф участка <strong>В виде целого числа. Копейки добавляют два знака!</strong></Form.Label>
                            <Form.Control type="text" placeholder="Тариф участка" name="rate"
                                          value={formDataUpdate.rate}
                                          onChange={handleChangeUpdate}
                                          required/>
                        </Form.Group>
                        <Form.Group controlId="formUpdateName">
                            <Form.Label>Владелец</Form.Label>
                            <Form.Control type="text" placeholder="ФИО" name="owner" value={formDataUpdate.owner}
                                          onChange={handleChangeUpdate}
                                          required/>
                        </Form.Group>
                        <Form.Group controlId="formUpdateLogin">
                            <Form.Label>Логин пользователя(буквенный)</Form.Label>
                            <Form.Control type="text" placeholder="Логин пользователя(буквенный)" name="login"
                                          value={formDataUpdate.login}
                                          onChange={handleChangeUpdate}
                                          required/>
                        </Form.Group>
                        <Form.Group controlId="formUpdateRole">
                            <Form.Label>Роль пользователя(не меняем отсюда)</Form.Label>
                            <Form.Control type="text" placeholder="Роль пользователя" name="role"
                                          value={formDataUpdate.role}
                                          readOnly/>
                        </Form.Group>
                        <Form.Group controlId="formUpdatePhone">
                            <Form.Label>Телефон пользователя(не меняем отсюда)</Form.Label>
                            <Form.Control type="text" placeholder="Телефон пользователя" name="phone"
                                          value={formDataUpdate.phone}
                                          readOnly/>
                        </Form.Group>
                        <Button variant="primary" onClick={handleSubmitUpdate} disabled={!buttonActive}>
                            Обновить информацию
                        </Button>
                    </Form>

                </>
            }
            {
              Number(plotNumber) > 0 &&
                <div className="mt-4">
                    <GetPayments plot={Number(plotNumber)} token={cookies.accessToken}/>
                </div>
            }
            {
              Number(plotNumber) > 0 &&
                <div className="mt-4">
                    <GetRates plot={Number(plotNumber)} token={cookies.accessToken} needUpdate={needUpdate}/>
                </div>
            }
        </>

  )
}

export default UpdatePlot
