import React from 'react'
import GetAndShowImage from '../../Files/GetAndShowImage'
import styles from './PublicPost.module.css'
import { Carousel } from 'react-bootstrap'
import PropTypes from 'prop-types'

PublicPost.propTypes = {
  post: PropTypes.object
}

function PublicPost (props) {
  const post = props.post
  return (
       <>
           <div className="col-12 text-left border-bottom mb-3">
               <h2>{post.header}</h2>
           </div>

           <div className="col-md-6 col-lg-8">
               <div className="text-left" dangerouslySetInnerHTML={{ __html: post.text }}/>
           </div>

           <div className="col-md-6 col-lg-4">
               <div className={styles.img }>
                   <GetAndShowImage fileName={post.image} altText={post.header}/>
               </div>
           </div>

               { post.images &&
               <div className="col-12 mb-4 mt-5 text-center">
                   <h4 className="mb-4">Дополнительные изображения</h4>
               <Carousel fade variant="dark">
                   {
                       post.images.map((additionalImage, key) => (
                           <Carousel.Item className={'col-12 ' + styles.slide} key={key}>
                               <GetAndShowImage fileName={additionalImage} altText={post.header + key}/>
                           </Carousel.Item>
                       ))
                   }
               </Carousel>
               </div>
               }
       </>
  )
}

export default PublicPost
