import React from 'react'
import GetMeters from '../../User/Meters/GetMeters'

function ViewMeters () {
  return (
        <>
            {
                <GetMeters/>
            }
        </>
  )
}

export default ViewMeters
