import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import { AppContext } from '../../App'
import { logo } from '../LeftColumn/LeftColumn'

function HelloColumn () {
  const { rightColumnBlock, showHello } = useContext(AppContext)

  return (
        <>
            { (rightColumnBlock === 'news') &&
            <div>
                    <div className='d-sm-block d-md-none text-center mb-3'>{logo}</div>
                {
                    showHello &&
                    <Alert variant={'secondary'} className="shadow bg-light">
                        <h1 className="h2">Добро пожаловать на портал для жителей Коттеджного посёлка «Аляска»</h1>
                    </Alert>
                }
            </div>
            }
        </>

  )
}

export default HelloColumn
