import React, { useContext, useState } from 'react'
import { Modal, Navbar } from 'react-bootstrap'
import LoginForm from '../LoginForm'
import api from '../../Api'
import { AppContext } from '../../App'
import styles from './TopColumn.module.css'
import { useCookies } from 'react-cookie'

function TopColumn () {
  const [showForm, setShowForm] = useState(false)
  const { isUserLogged, setIsUserLogged } = useContext(AppContext)
  const { setRightColumnBlock } = useContext(AppContext)
  const [cookies, , removeCookie] = useCookies(['accessToken'])

  const logOut = (e) => {
    e.preventDefault()
    api
      .get('/auth/logOut', {
        Authorization: 'Bearer ' + cookies.accessToken
      })
      .then(() => {
        removeCookie('accessToken')
        setIsUserLogged(false)
        setRightColumnBlock('news')
      })
  }

  return (
        <>
            <div className={styles.topBg}>
                <Navbar expand="lg" variant="primary" className="justify-content-end" id="btn-login">
                    {isUserLogged
                      ? <Navbar.Brand href="/" onClick={logOut}>Выйти</Navbar.Brand>
                      : <Navbar.Brand href="" onClick={() => setShowForm(true)} className="text-primary"
                                        role="button">Войти</Navbar.Brand>
                    }
                </Navbar>
                <Modal show={showForm} onHide={() => setShowForm(false)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {LoginForm(setShowForm)}
                    </Modal.Body>
                </Modal>

            </div>
        </>
  )
}

export default TopColumn
